import axios from "axios";

const BASE_URL = process.env.REACT_APP_STRAPI_API_URL;

export const getGamaMobilityContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gm-mains/r3t7e5ysjpsytg3sa566s1g9?populate[0]=Navbar.Logo&populate[1]=Navbar.TopLinks&populate[2]=Navbar.NavLinks&populate[3]=Navbar.NavButton`
  );

  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/r3t7e5ysjpsytg3sa566s1g9?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/r3t7e5ysjpsytg3sa566s1g9?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Card.Button`
  );

  const { data: footer } = await axios.get(
    `${BASE_URL}/gm-mains/r3t7e5ysjpsytg3sa566s1g9?populate[0]=Footer.Logo&populate[1]=Footer.SocialNetwork.Icon&populate[2]=Footer.FooterLinks.FooterLink&populate[3]=Footer.FooterNewsletter.NewsletterButton&populate[4]=Footer.ContactCard.Icon&populate[5]=Footer.BottomArea.BottomLinks`
  );

  return {
    navbar: navbar.data.Navbar,
    header: header.data.Header,
    sections: sections.data.Sections,
    footer: footer.data.Footer,
  };
};

export const getGamaMobilityContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/eszng6xrlbj3dc5lhsnbcxl8?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/eszng6xrlbj3dc5lhsnbcxl8?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );

  return {
    header: header.data.Header,
    sections: sections.data.Sections,
  };
};

export const getGamaMobilityUS = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/fl2nomuxt0oequshjb2fk174?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/fl2nomuxt0oequshjb2fk174?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );

  return {
    header: header.data.Header,
    sections: sections.data.Sections,
  };
};

export const getGamaMobilityBlog = async () => {
  const { data } = await axios.get(
    `${BASE_URL}/gm-mains/a64bm1bamqr2s4l12xh0xqnj?populate[0]=Header.Title&populate[1]=Header.BannerImage&populate[2]=Sections.Card.Icon&populate[3]=Sections.Card.Button`
  );

  const response = await axios.get(
    `${process.env.REACT_APP_STRAPI_API_URL}/blogs/?populate=Imagen`
  );

  return {
    header: data?.data?.Header,
    sections: response?.data?.data,
  };
};
