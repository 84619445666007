import { BrowserRouter, Routes, Route } from "react-router-dom";

import Mobility from "../domain/Mobility";
import MobilityUS from "../domain/MobilityUS";
import ContactMobility from "../domain/ContactMobility/index";
import Blog from "../domain/Blog";

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Mobility />} />
        <Route path="/contacto-mobility" element={<ContactMobility />} />
        <Route path="/mobility-nosotros" element={<MobilityUS />} />
        <Route path="/mobility" element={<Mobility />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postTitle" element={<Blog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesWrapper;
