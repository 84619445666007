import { Col, Divider, Row, Spin } from "antd";
import React from "react";
import { Helmet } from "react-helmet-async";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { useMediaPredicate } from "react-media-hook";
import GoToTop from "../../../components/GoToTop";
import ReactMarkdown from "react-markdown";

function BlogDetail({
  handleDetails,
  blogSections,
  handlePreviousPost,
  handleNextPost,
  relatedSection,
  handleShowDetails,
}) {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const monthsInSpanish = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const date = new Date(blogSections?.Imagen?.createdAt);
  const day = date.getDate();
  const month = monthsInSpanish[date.getMonth()];
  const year = date.getFullYear();
  const formattedDateStr = `${day} de ${month} de ${year}`;

  const defaultTitle = "Contenido no disponible";
  const defaultDescription =
    "Por el momento no tenemos información para mostrar.";
  const title = blogSections?.Titulo || defaultTitle;
  const description =
    blogSections?.Contenido?.[0]?.children?.[0]?.text || defaultDescription;

  const renderBlock = (block) => {
    if (!block || !block.children || block.children.length === 0) return null;

    switch (block.type) {
      case "heading": {
        const Tag = `h${block.level}`;
        return <Tag>{block.children[0]?.text}</Tag>;
      }
      case "paragraph":
        return (
          <p>
            {block.children.map((child, index) => {
              let content = child.text;

              if (child.bold && child.italic) {
                return (
                  <strong key={index}>
                    <em>{content}</em>
                  </strong>
                );
              }

              if (child.bold) {
                return <strong key={index}>{content}</strong>;
              }

              if (child.italic) {
                return <em key={index}>{content}</em>;
              }

              return <span key={index}>{content}</span>;
            })}
          </p>
        );
      default:
        return null;
    }
  };

  const renderContent = (content) => {
    return content?.map((block, index) => (
      <div key={index}>{renderBlock(block)}</div>
    ));
  };

  return (
    <div>
      <Helmet>
        <title>{title} | Gama Mobility</title>
        <meta name="description" content={description} />
      </Helmet>
      {blogSections === undefined ? (
        <Row style={{ height: "500px" }} align={"middle"} justify={"center"}>
          <Spin />
        </Row>
      ) : (
        <>
          <GoToTop />
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Col
              onClick={handleShowDetails}
              style={{
                color: "#FF5F00",
                fontWeight: "400",
                marginBottom: "15px",
                marginTop: "80px",
                cursor: "pointer",
              }}
            >{`<- Volver atrás`}</Col>
            <img
              alt="truck on a mountain"
              src={blogSections?.Imagen?.url}
              style={{ width: "100%", height: "auto", marginBottom: "32px" }}
            />
            <Col
              style={{
                fontWeight: "700",
                marginBottom: "24px",
                fontSize: "32px",
              }}
            >
              {blogSections?.Titulo}
            </Col>
            <Col
              style={{
                width: "934px",
                lineHeight: "25px",
                marginBottom: "24px",
              }}
            >
              {renderContent(blogSections?.Contenido)}
            </Col>
            <Row>
              <Col>
                <CalendarOutlined
                  style={{ color: "#FF5F00", marginRight: "12px" }}
                />
              </Col>
              <Col style={{ color: "#828282" }}>
                {blogSections?.length === 0 || blogSections === null
                  ? `Publicado el 5 de septiembre de 2023`
                  : formattedDateStr}
              </Col>
            </Row>
            <Row>
              <Col>
                <UserOutlined
                  style={{ color: "#FF5F00", marginRight: "12px" }}
                />
              </Col>
              <Col style={{ color: "#828282" }}>Admin</Col>
            </Row>
          </Row>
          <Row justify="center" style={{ marginBottom: "50px" }}>
            <Col
              onClick={handlePreviousPost}
              style={{
                border: "1px solid #E0E0E0",
                padding: "1px 14px 1px 14px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                cursor: "pointer",
                color: "#828282",
              }}
            >
              Noticia previa
            </Col>
            <Col
              onClick={handleShowDetails}
              style={{
                border: "1px solid #E0E0E0",
                padding: "1px 14px 1px 14px",
                cursor: "pointer",
                color: "#828282",
              }}
            >
              Volver al Blog
            </Col>
            <Col
              onClick={handleNextPost}
              style={{
                border: "1px solid #E0E0E0",
                padding: "1px 14px 1px 14px",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                cursor: "pointer",
                color: "#828282",
              }}
            >
              Siguiente Noticia
            </Col>
          </Row>
          <Divider />
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Col style={{ fontWeight: "600", fontSize: "24px" }}>
              Noticias Relacionadas
            </Col>
          </Row>
          <Row justify="center">
            <Row
              style={{
                display: "grid",
                gridTemplateColumns: biggerThan900
                  ? "repeat(3, 1fr)"
                  : "repeat(1, 1fr)",
                gridTemplateRows: biggerThan900
                  ? "repeat(1, 1fr)"
                  : "repeat(1, 1fr)",
                marginBottom: "100px",
              }}
            >
              {relatedSection?.map((post, index) => {
                return (
                  <div
                    key={post?.id || index}
                    style={{
                      width: biggerThan900 ? "301px" : "90%",
                      height: biggerThan900 ? "450px" : "480px",
                      border: "1px solid #E0E0E0",
                      marginLeft: "15px",
                      marginRight: "15px",
                      marginBottom: "15px",
                      marginTop: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <img
                        alt={post?.Imagen.name}
                        src={post?.Imagen.url}
                        style={{
                          width: "100%",
                          height: "160px",
                          backgroundSize: "contain",
                          borderTopRightRadius: "10px",
                          borderTopLeftRadius: "10px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: biggerThan900 ? "18px" : "22px",
                        fontWeight: "600",
                        marginLeft: "30px",
                        marginTop: "16px",
                        lineHeight: "29px",
                        marginBottom: "16px",
                      }}
                    >
                      {post.Titulo.length >= 80
                        ? post.Titulo.slice(0, 80) + "..."
                        : post.Titulo}
                    </div>
                    <div
                      style={{
                        fontSize: biggerThan900 ? "14px" : "12px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        color: "#828282",
                        width: biggerThan900 ? "250px" : "80%",
                        marginLeft: "30px",
                        minHeight: "108px",
                      }}
                    >
                      {biggerThan900
                        ? post?.Contenido?.find(
                            (item) => item.type === "paragraph"
                          )?.children?.[0]?.text?.slice(0, 150) + "..." ||
                          "Información no disponible."
                        : post?.Contenido?.find(
                            (item) => item.type === "paragraph"
                          )?.children?.[0]?.text?.slice(0, 180) + "..." ||
                          "Información no disponible."}
                    </div>
                    <div
                      onClick={() => handleDetails(post, index)}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "#FF5F00",
                        textDecoration: "underline",
                        fontSize: biggerThan900 ? "" : "12px",
                        marginTop: biggerThan900 ? "5px" : "",
                        paddingBottom: "15px",
                        cursor: "pointer",
                        width: "95%",
                      }}
                    >
                      {`Leer la nota completa >`}
                    </div>
                  </div>
                );
              })}
            </Row>
          </Row>
        </>
      )}
    </div>
  );
}

export default BlogDetail;
