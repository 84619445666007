import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGamaMobilityContent,
  getGamaMobilityContactContent,
  getGamaMobilityUS,
  getGamaMobilityBlog,
} from "../../requests";

export const getGamaMobilityHomeContent = createAsyncThunk(
  "gamamobility/getGamaMobilityHomeContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections, footer, navbar } =
        await getGamaMobilityContent();

      return {
        navbar,
        header,
        sections,
        footer,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaMobilityContact = createAsyncThunk(
  "gamamobility/getGamaMobilityContact",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getGamaMobilityContactContent();

      const { BannerImage, Button, Title } = header;

      return {
        header: {
          bannerImage: BannerImage.data[0],
          bannerButton: Button[0],
          title: Title.Title,
          detail: Title.Detail,
        },
        sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaMobilityBlogContent = createAsyncThunk(
  "gamamobility/getGamaMobilityBlogContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getGamaMobilityBlog();

      return {
        header: {
          bannerImage: header.BannerImage?.data?.[0]?.url,
          Title: header.Title?.Title,
          Detail: header.Title?.Detail,
        },
        sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaMobilityUSContent = createAsyncThunk(
  "gamamobility/getGamaMobilityUSContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getGamaMobilityUS();

      const { BannerImage, Button, Title } = header;

      return {
        header: {
          bannerImage: BannerImage.data[0],
          bannerButton: Button[0],
          title: Title.Title,
          detail: Title.Detail,
        },
        sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);
